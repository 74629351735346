<template>
	<div>
		
土拍并购
	</div>
</template>

<script>
export default {
	name: 'PcWebsiteBingo',

	data() {
		return {
			
		};
	},

	mounted() {
		
	},

	methods: {
		
	},
};
</script>

<style lang="scss" scoped>

</style>